/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
 Grid, FormHelperText, Select, MenuItem,
} from '@material-ui/core';
import Input from 'components/Input';
import { PasswordIcon, EmailIcon, CPFIcon } from 'assets/icons';
import CheckboxTerms from 'components/CheckboxTerms';
import Validator from 'utils/validator';
import InputMask from 'inputmask';
import Title from '../Title';

const validator = new Validator();

export default function UserData({
  userDataRegister,
  clicked,
  setChecked,
  userDataRegisterState,
  emailValid,
  validateEmail,
  cpfValid,
  validateCpf,
  resetUser,
}: any) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpf, setCpf] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [gender, setGender] = useState(-1);
  const [errors, setErrors] = useState();
  const [emailError, setEmailError] = useState();
  const [cpfError, setCpfError] = useState();

  const rules = [
    {
      field: 'email',
      method: validator.isEmail,
      value: email,
      message: 'Email inválido',
    },
    {
      field: 'cpf',
      method: validator.isCpf,
      value: cpf,
      message: 'CPF inválido',
    },
    {
      field: 'password',
      method: validator.checkPassword,
      value: [password, confirmPassword],
      message: 'As senhas não coincidem',
    },
    {
      field: 'gender',
      method: validator.checkSelect,
      value: gender,
      message: 'Selecione um gênero',
    },
  ];

  useEffect(() => {
    resetUser('phoneValid');
  }, [resetUser]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function formValidation() {
    setErrors(validator.validateForm(rules));
  }

  useEffect(() => {
    if (errors !== undefined) {
      if (!errors.email.error && !errors.cpf.error && !errors.password.error && terms) {
        validateEmail(email);
        validateCpf(cpf);
      } else {
        setChecked(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  // useEffect(() => {
  //   if (emailValid !== undefined && cpfValid !== undefined) {
  //     if (emailValid === true && cpfValid === true) {
  //       userDataRegister({ email, password, cpf, gender });
  //       setChecked(true);
  //       setEmailError(false);
  //       setCpfError(false);
  //     } else if (emailValid === false && cpfValid === false) {
  //       setEmailError(false);
  //       setCpfError(false);
  //       setChecked(false);
  //     } else if (emailValid === true && cpfValid === false) {
  //       setEmailError(false);
  //       setCpfError(true);
  //       setChecked(false);
  //     } else if (emailValid === false && cpfValid === true) {
  //       setEmailError(true);
  //       setCpfError(false);
  //       setChecked(false);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [emailValid, cpfValid]);

  useEffect(() => {
    if (emailValid !== undefined && cpfValid !== undefined) {
      if (emailValid === true && cpfValid === true) {
        userDataRegister({ email, password, cpf, gender });
        setChecked(true);
        setEmailError('');
        setCpfError('');
      } else if (emailValid !== true && cpfValid !== true) {
        setEmailError(emailValid);
        setCpfError(cpfValid);
        setChecked(false);
      } else if (emailValid === true && cpfValid !== false) {
        setEmailError('');
        setCpfError(cpfValid);
        setChecked(false);
      } else if (emailValid !== true && cpfValid === true) {
        setEmailError(emailValid);
        setCpfError('');
        setChecked(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValid, cpfValid]);

  useEffect(() => {
    if (clicked) {
      formValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked]);

  useEffect(() => {
    if (userDataRegisterState !== undefined) {
      setEmail(userDataRegisterState.email);
      setCpf(userDataRegisterState.cpf);
      setGender(userDataRegisterState.gender);
    }
  }, [userDataRegisterState]);

  function handleInput(prop: string, e: any) {
    switch (prop) {
      case 'email': {
        setEmail(e.target.value);
        break;
      }
      case 'password': {
        setPassword(e.target.value);
        break;
      }
      case 'cpf': {
        if (e.target.value === '') {
          setCpf('');
          break;
        }
        const mask: any = InputMask({ mask: '999.999.999-99' }).mask(e.target);
        setCpf(mask.maskset.buffer.join(''));
        break;
      }
    }
  }

  return (
    <React.Fragment>
      <Title text="Dados Pessoais" />
      <Grid item md={12} container justify="center" spacing={4}>
        <Grid item xs={12} md={6} container justify="center" alignItems="center" spacing={4}>
          <Grid item md={12} container>
            <Input
              name="email"
              placeholder="Email"
              value={email}
              icon={EmailIcon}
              inputProps={{
                maxLength: 100
              }}
              onChange={evt => handleInput('email', evt)}
              error={errors ? errors.email.error : false}
              errorText={errors ? errors.email.message : ''}
            />
            {
              emailError
              ?
              emailError === "EMAIL_NOT_VALID"
                ?
                  (<FormHelperText error>Email inválido</FormHelperText>)
                :
                  emailError === "EMAIL_ALREADY_REGISTERED"
                  ?
                    (<FormHelperText error>Email já cadastrado</FormHelperText>)
                  :
                    ''
                :
                  ''
            }
          </Grid>
          <Grid item md={12} container>
            <Input
              name="cpf"
              value={cpf}
              placeholder="CPF"
              icon={CPFIcon}
              onChange={evt => handleInput('cpf', evt)}
              error={errors ? errors.cpf.error : false}
              errorText={errors ? errors.cpf.message : ''}
            />
            {
              cpfError
              ?
                cpfError === 400
                ?
                  (<FormHelperText error>CPF inválido</FormHelperText>)
                :
                  cpfError === 422
                  ?
                    (<FormHelperText error>CPF já cadastrado</FormHelperText>)
                  :
                    ''
                :
                  ''
            }
          </Grid>
          <Grid item md={12} container>
            <Select
              value={gender}
              fullWidth
              onChange={(evt: any) => setGender(evt.target.value)}
              style={{ color: 'gray' }}
              error={errors ? errors.gender.error : false}
              inputProps={{
                name: 'gender',
                id: 'gender',
              }}
            >
              <MenuItem disabled value={-1}>
                Gênero
              </MenuItem>
              <MenuItem value={0}>Feminino</MenuItem>
              <MenuItem value={1}>Masculino</MenuItem>
              <MenuItem value={2}>Outro</MenuItem>
              <MenuItem value={3}>Desejo não informar</MenuItem>
            </Select>
            {// eslint-disable-next-line no-nested-ternary
            errors ? (
              errors.gender.error ? (
                <FormHelperText error>{errors.gender.message}</FormHelperText>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} container justify="center" alignItems="center" spacing={4}>
          <Grid item md={12} container>
            <Input
              name="password"
              placeholder="Senha"
              type="password"
              icon={PasswordIcon}
              onChange={evt => handleInput('password', evt)}
              error={errors ? errors.password.error : false}
              errorText={errors ? errors.password.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Input
              name="confirmPassword"
              placeholder="Confirmar senha"
              type="password"
              icon={PasswordIcon}
              onChange={evt => setConfirmPassword(evt.target.value)}
              error={errors ? errors.password.error : false}
              errorText={errors ? errors.password.message : ''}
            />
          </Grid>
        </Grid>
        <Grid item md={12} container justify="flex-end" alignItems="center">
          <CheckboxTerms terms={terms} handleChange={setTerms} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
