import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as AuthActions from 'store/ducks/auth/actions';
import { ApplicationState } from 'store';

type DispatchProps = typeof AuthActions;

interface Params {}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(AuthActions, dispatch);

type Props = RouteComponentProps<Params> & DispatchProps;

function Logout({ logout }: Props) {
  useEffect(() => {
    logout();
  });

  return <div />;
}

const mapStateToProps = (state: ApplicationState) => ({
  userAuth: state.user.userAuth,
  token: state.auth.token,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Logout);
