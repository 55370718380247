import React, { useCallback, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Identify from 'pages/Identify';
import history from './history';
import { routes } from './routes';
import { PrivateRoute } from './components/PrivateRoute';

export default function Routes() {
  const getPageCallback = useCallback((path: string) => {
    history.push(path);
  }, []);

  useEffect(() => {
    if (history.location.pathname === '/') {
      getPageCallback('/identify');
    }
  }, [getPageCallback]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {routes.map(route => (route.protected ? (
          <PrivateRoute key={route.path} path={route.path} component={route.component} />
          ) : (
            <Route key={route.path} path={route.path} component={route.component} />
          )))}
        <Route path="/unauthenticated" render={() => <div>Forbidden</div>} />
        <Route path="/" component={Identify} />
      </Switch>
    </ConnectedRouter>
  );
}
