import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Password from 'assets/icons/ICONE-SENHA.png';
import Input from 'components/Input';
import Button from 'components/Button';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as AuthActions from 'store/ducks/auth/actions';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationState } from 'store';
import CircularProgress from '@material-ui/core/CircularProgress';
import DocumentCard, { ImageType } from 'components/DocumentCard';
import { SelfieIcon, LicenseIcon, PaperIcon } from 'assets/icons';
import api from 'services/api';
import { UserDocuments } from 'models/User/userDocuments';
import history from 'routes/history';
import { Container, Block, CardContainer } from './styles';
import Title from '../Roll/components/Title';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(AuthActions, dispatch);

const mapStateToProps = (state: ApplicationState) => ({});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & DispatchProps & StateProps;

function DriverDocuments(props: Props) {
  const [status, setStatus] = useState<UserDocuments>({
    cnh: null,
    criminal_records: null,
    selfie: null,
    vehicle_document: null,
    cnh_locked: false,
    criminal_records_locked: false,
    selfie_locked: false,
    vehicle_document_locked: false,
  });

  const [toSend, setToSend] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      status.cnh != null
      && status.criminal_records != null
      && status.selfie != null
      && status.vehicle_document != null
    ) {
      history.push('/identify');
    }
  }, [status]);

  useEffect(() => {
    async function getStatus() {
      try {
        const res = await api.get<UserDocuments>('/account/driver-documents', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (
          res.data.cnh != null
          && res.data.criminal_records != null
          && res.data.selfie != null
          && res.data.vehicle_document != null
        ) {
          history.push('/identify');
        } else {
           setStatus(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    getStatus();
  }, []);

  function handleSubmit() {
    setLoading(true);

    Promise.all([ImageType.PROFILE, ImageType.LICENSE, ImageType.CRLV, ImageType.RECORDS].map(async (imageType) => {
      if (toSend[imageType] && !status[`${imageType}_locked`]) {
        try {
          const res = await sendImage(imageType, toSend[imageType]!);
          return res;
        } catch (err) {
           console.log('some');
        }
      }
      return null;
    }))
    .then((values) => {
      const [resSelfie, resCnh, resCRLV, resRecords] = values;
      setStatus({
        ...status,
        ...(resSelfie != null && { selfie: resSelfie.data.selfie }),
        ...(resCnh != null && { cnh: resCnh.data.cnh }),
        ...(resCRLV != null && { vehicle_document: resCRLV.data.vehicle_document }),
        ...(resRecords != null && { criminal_records: resRecords.data.criminal_records }),
      });
    })
    .catch((err) => {
      setLoading(false);
    });
  }

  return (
    <Container container justify="center">
      <Block
        container
        item
        md={12}
        justify="center"
        style={{ overflowY: 'scroll', overflowX: 'hidden'}}
      >
        <Grid item md={12} container style={{ margin: '5%' }}>
          <Title text="Envio de documentos" />
        </Grid>

        <Grid
          item
          md={12}
          container
          justify="center"
          alignContent="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          <CardContainer item xs={12} sm={12} md={3}>
            <DocumentCard
              handleUpdate={(file: string) => setToSend({ ...toSend, selfie: file })}
              filePreview={status.selfie}
              imageType={ImageType.PROFILE}
              title="SELFIE"
              icon={SelfieIcon}
              locked={status.selfie_locked!}
              description="Envie uma foto do seu rosto. Por favor tire a foto em um local com boa iluminação e não utilize máscaras ou óculos."
            />
          </CardContainer>

          <CardContainer item xs={12} sm={12} md={3}>
            <DocumentCard
              handleUpdate={(file: string) => setToSend({ ...toSend, cnh: file })}
              filePreview={status.cnh}
              imageType={ImageType.LICENSE}
              title="CNH"
              icon={LicenseIcon}
              locked={status.cnh_locked!}
              description="Envie uma foto ou digitalização da sua carteira de motorista aberta."
            />
          </CardContainer>

          <CardContainer item xs={12} sm={12} md={3}>
            <DocumentCard
              handleUpdate={(file: string) => setToSend({ ...toSend, vehicle_document: file })}
              icon={LicenseIcon}
              filePreview={status.vehicle_document}
              imageType={ImageType.CRLV}
              title="CRLV"
              locked={status.vehicle_document_locked!}
              description="Envie uma foto ou digitalização do documento aberto do seu veículo."
            />
          </CardContainer>

          <CardContainer item xs={12} sm={12} md={3}>
            <DocumentCard
              handleUpdate={(file: string) => setToSend({ ...toSend, criminal_records: file })}
              filePreview={status.criminal_records}
              imageType={ImageType.RECORDS}
              title="ANTECEDENTES"
              icon={PaperIcon}
              locked={status.criminal_records_locked!}
              description="Acesse o link abaixo e baixe a sua certidão de bons antecedentes criminais."
            />
          </CardContainer>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          component="p"
          container
          style={{
 marginLeft: '5%', marginRight: '5%', color: 'gray',
}}
        >
          Para obter o certificado de bons antecedentes criminais acesse o link:&nbsp;&nbsp;
          <a target="_blank" href="https://servicos.dpf.gov.br/antecedentes-criminais/certidao">
            https:// servicos.dpf.gov.br/antecedentes-criminais/certidao
          </a>
          <br>
        </br>
        <br>
        </br>
        </Grid>
        
        <Grid item md={6} sm={6} xs={12} container justify="center" alignItems="center">
          {!loading ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              style={{ width: 100, height: 35 }}
            >
            Enviar
            </Button>
        ) : <CircularProgress style={{ marginTop: '10px' }} size={30} />}
        </Grid>        
        <Grid item md={6} sm={6} xs={12} container justify="center" alignItems="center">
          <Button
            color="secondary"
            variant="contained"
            style={{ width: 100, height: 35, margin: '24px 0 24px 0' }}
            onClick={() => history.push('/logout')}
          >
            Sair
          </Button>
        </Grid>
        <br>
        </br>
      </Block>
    </Container>
  );
}

async function sendImage(imageType: ImageType, image: string) {
      let endpoint = '';
      switch (imageType) {
      case ImageType.PROFILE:
        endpoint = 'driver-selfie';
        break;
      case ImageType.CRLV:
        endpoint = 'driver-vehicle-document';
        break;
      case ImageType.RECORDS:
        endpoint = 'driver-criminal-records';
        break;
      case ImageType.LICENSE:
        endpoint = 'driver-cnh';
        break;
      default:
        endpoint = `driver-${imageType}`;
        break;
    }

    const formData = new FormData();
    formData.set('image', image);
    return api.post(`/account/${endpoint}`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverDocuments);
