export enum AuthActionTypes {
  AUTO_LOGIN = '@@auth/AUTO_LOGIN',
  LOGIN_REQUEST = '@@auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@@auth/LOGIN_FAILURE',
  LOGOUT = '@@auth/LOGOUT',
  
  RECOVERY_PASSWORD_REQUEST = '@@auth/RECOVERY_PASSWORD_REQUEST',
  RECOVERY_PASSWORD_SUCCESS = '@@auth/RECOVERY_PASSWORD_SUCCESS',
  RECOVERY_PASSWORD_FAILURE = '@@auth/RECOVERY_PASSWORD_FAILURE',

  UPDATE_PASSWORD_REQUEST = '@@auth/UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS = '@@auth/UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE = '@@auth/UPDATE_PASSWORD_FAILURE',
}

export interface AuthState {
  readonly isLoading: boolean;
  readonly token: string;
  readonly error?: any;
  readonly success?: any;
  readonly recovery: any;
}
