import styled from 'styled-components';
import background from 'assets/images/BG-CADASTRO.png';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
  && {
    font-family: 'Lato', sans-serif;
    min-height: 100vh;
    @media (min-width: 768px) and (orientation: landscape) {
      background: url(${background}), #f5f5f5;
      background-repeat: no-repeat;
      background-size: 30% 50%;
      background-position-y: bottom;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      background-size: contain;
      background-position-y: 500px;
    }
    overflow-y: scroll;
  }
`;

export const Block = styled(Grid)`
  && {
    @media (min-width: 768px) and (orientation: landscape) {
      margin: 0% 20%;
    }
    background-color: #fff;
  }
`;
