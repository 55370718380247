import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'routes/history';
import { auth } from './auth/reducer';
import { user } from './user/reducer';
import { AuthActionTypes } from './auth/types';

export const reducers = combineReducers({
  router: connectRouter(history),
  auth,
  user,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === AuthActionTypes.LOGOUT) {
    localStorage.clear();
    sessionStorage.clear();
    state = undefined;
    window.location.href = '/login';
  }
  return reducers(state, action);
};

export default rootReducer;
