import React, { useState, useEffect } from 'react';
import {
 Grid, Select, MenuItem, FormHelperText,
} from '@material-ui/core';
import Input from 'components/Input';
import {
 PersonIcon, WalletIcon, PasswordIcon, CPFIcon,
} from 'assets/icons';
import InputMask from 'inputmask';
import Validator from 'utils/validator';
import Title from '../Title';

const validator = new Validator();

export default function BankData({
 clicked, setChecked, userBankRegister, bankDataRegister, banks, getBanks, handleSignup, userDataRegisterState,
}: any) {
  const [holderName, setHolderName] = useState('');
  const [bankNumber, setBankNumber] = useState('none');
  const [bankBranchNumber, setBankBranchNumber] = useState('');
  const [bankBranchNumberDV, setBankBranchNumberDV] = useState('');
  const [taxDocument, setTaxDocument] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountNumberDV, setAccountNumberDV] = useState('');
  const [variation, setVariation] = useState('');
  const [type, setType] = useState('none');
  const [banksState, setBanksState] = useState([]);
  const [errors, setErrors] = useState();

  const rules = [
    {
      field: 'holderName', method: validator.isName, value: holderName, message: 'Titular inválido',
    },
    {
      field: 'bankNumber', method: validator.checkSelectString, value: bankNumber, message: 'O campo Banco é obrigatório',
    },
    {
      field: 'bankBranchNumber', method: validator.isBankBranch, value: bankBranchNumber, message: 'Número de Agência inválido',
    },
    {
      field: 'bankBranchNumberDV', method: validator.isBranchDigit, value: bankBranchNumberDV, message: 'DV inválido',
    },
    {
      field: 'taxDocument', method: validator.isCpf, value: taxDocument, message: 'CPF inválido',
    },
    {
      field: 'accountNumber', method: validator.isBankAccount, value: accountNumber, message: 'Número da conta inválido',
    },
    {
      field: 'accountNumberDV', method: validator.isAccountDigit, value: accountNumberDV, message: 'DV inválido',
    },
    {
      field: 'variation', method: validator.isBankVariation, value: variation, message: 'Variação inválida',
    },
    {
      field: 'type', method: validator.checkSelectString, value: type, message: 'O campo conta é obrigatório',
    },
  ];

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    if (userDataRegisterState !== undefined) {
      setTaxDocument(userDataRegisterState.cpf);
    }
  }, [userDataRegisterState]);

  useEffect(() => {
    if (banks !== undefined) {
      setBanksState(banks.banks);
    }
  }, [banks]);

  useEffect(() => {
    if (userBankRegister !== undefined) {
      setHolderName(userBankRegister.holderName);
      setBankNumber(userBankRegister.bankNumber);
      setBankBranchNumber(userBankRegister.bankBranchNumber);
      setBankBranchNumberDV(userBankRegister.bankBranchNumberDV);
      // setTaxDocument(userBankRegister.taxDocument);
      setAccountNumber(userBankRegister.accountNumberDV);
      setAccountNumberDV(userBankRegister.accountNumberDV);
      setVariation(userBankRegister.variation);
      setType(userBankRegister.type);
    }
  }, [userBankRegister]);

  function renderBanksSelect(bankParam: any) {
    return bankParam.map((bank: any) => (
      <MenuItem key={bank.number} value={bank.number}>
        {`${bank.number} - ${bank.name}`}
      </MenuItem>
      ));
  }

  function handleInput(prop: string, value: any) {
    switch (prop) {
      case 'accountNumber': {
        setAccountNumber(value);
        break;
      }
      case 'accountNumberDV': {
        setAccountNumberDV(value);
        break;
      }
      case 'taxDocument': {
        const mask: any = InputMask({ mask: '999.999.999-99' }).mask(value.target);
        setTaxDocument(mask.maskset.buffer.join(''));
        break;
      }
      case 'variation': {
        setVariation(value);
        break;
      }
      case 'bankBranchNumber': {
        setBankBranchNumber(value);
        break;
      }
      case 'bankBranchNumberDV': {
        setBankBranchNumberDV(value);
        break;
      }
      case 'holderName': {
        setHolderName(value);
        break;
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function formValidation() {
    setErrors(validator.validateForm(rules));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleSubmit() {
    // eslint-disable-next-line max-len
    if (!errors.holderName.error && !errors.bankNumber.error && !errors.bankBranchNumber.error && !errors.taxDocument.error && !errors.accountNumber.error && !errors.accountNumberDV.error && !errors.variation.error && !errors.type.error) {
      if (bankBranchNumberDV) {
        if (!errors.bankBranchNumberDV.error) {
          bankDataRegister({
 holderName, bankNumber, variation, type, accountNumber: accountNumberDV ? `${accountNumber}-${accountNumberDV}` : accountNumber, bankBranchNumber: bankBranchNumberDV ? `${bankBranchNumber}-${bankBranchNumberDV}` : bankBranchNumber,
        });
          return true;
        }
      } else {
        bankDataRegister({
 holderName, bankNumber, variation, type, accountNumber: accountNumberDV ? `${accountNumber}-${accountNumberDV}` : accountNumber, bankBranchNumber: bankBranchNumberDV ? `${bankBranchNumber}-${bankBranchNumberDV}` : bankBranchNumber,
});
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (clicked) {
      formValidation();
    }
  }, [clicked]);

  useEffect(() => {
    if (errors !== undefined) {
      const submit = handleSubmit();
      if (submit) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [errors]);

  useEffect(() => {
    if (userBankRegister !== undefined) {
      handleSignup();
    }
  }, [userBankRegister]);

  return (
    <React.Fragment>
      <Title text="Dados bancários" />
      <Grid item md={12} container justify="center" spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={12} container>
            <Input
              name="holderName"
              placeholder="Titular"
              icon={PersonIcon}
              value={holderName}
              inputProps={{
                maxLength: 100
              }}
              onChange={evt => handleInput('holderName', evt.target.value)}
              error={errors ? errors.holderName.error : false}
              errorText={errors ? errors.holderName.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Select
              value={bankNumber}
              fullWidth
              onChange={(evt: any) => setBankNumber(evt.target.value)}
              style={{ color: 'gray' }}
              inputProps={{
                name: 'bankNumber',
                id: 'bankNumber',
              }}
              error={errors ? errors.bankNumber.error : false}
            >
              <MenuItem disabled value="none">
                Banco
              </MenuItem>
              {
                renderBanksSelect(banksState || [])
              }
            </Select>
            {
              // eslint-disable-next-line no-nested-ternary
              errors ? errors.bankNumber.error
                ? (<FormHelperText error>{errors.bankNumber.message}</FormHelperText>)
                : ''
              : ''
            }
          </Grid>
          <Grid item md={12} container>
            <Grid item xs={9} md={9} container>
              <Input
                name="bankBranchNumber"
                placeholder="Número da Agência"
                icon={WalletIcon}
                value={bankBranchNumber}
                inputProps={{
                  maxLength: 4,
                }}
                onChange={evt => handleInput('bankBranchNumber', evt.target.value)}
                error={errors ? errors.bankBranchNumber.error : false}
                errorText={errors ? errors.bankBranchNumber.message : ''}
              />
            </Grid>
            <Grid item xs={3} md={3} container>
              <Input
                name="bankBranchNumberDV"
                placeholder="DV"
                icon={PasswordIcon}
                value={bankBranchNumberDV}
                inputProps={{
                  maxLength: 1,
                }}
                onChange={evt => handleInput('bankBranchNumberDV', evt.target.value)}
                error={bankBranchNumberDV ? errors ? errors.bankBranchNumberDV.error : false : false}
                errorText={bankBranchNumberDV ? errors ? errors.bankBranchNumberDV.message : '' : ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={12} container>
            <Input
              name="taxDocument"
              placeholder="CPF"
              icon={CPFIcon}
              disabled
              value={taxDocument}
              onChange={evt => handleInput('taxDocument', evt)}
              error={errors ? errors.taxDocument.error : false}
              errorText={errors ? errors.taxDocument.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Grid item xs={9} md={9} container>
              <Input
                name="accountNumber"
                placeholder="Número da conta"
                icon={WalletIcon}
                value={accountNumber}
                inputProps={{
                  maxLength: 13,
                }}
                error={errors ? errors.accountNumber.error : false}
                errorText={errors ? errors.accountNumber.message : ''}
                onChange={evt => handleInput('accountNumber', evt.target.value)}
              />
            </Grid>
            <Grid item xs={3} md={3} container>
              <Input
                name="accountNumberDV"
                placeholder="DV"
                icon={PasswordIcon}
                value={accountNumberDV}
                inputProps={{
                  maxLength: 2,
                }}
                error={errors ? errors.accountNumberDV.error : false}
                errorText={errors ? errors.accountNumberDV.message : ''}
                onChange={evt => handleInput('accountNumberDV', evt.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item md={12} container>
            <Input
              name="variation"
              placeholder="Variação"
              icon={WalletIcon}
              value={variation}
              inputProps={{
                maxLength: 3,
              }}
              error={errors ? errors.variation.error : false}
              errorText={errors ? errors.variation.message : ''}
              onChange={evt => handleInput('variation', evt.target.value)}
            />
          </Grid>
          <Grid item md={12} container>
            <Select
              value={type}
              fullWidth
              onChange={(evt: any) => setType(evt.target.value)}
              style={{ color: 'gray' }}
              error={errors ? errors.type.error : false}
              inputProps={{
                name: 'type',
                id: 'type',
              }}
            >
              <MenuItem disabled value="none">
                Conta
              </MenuItem>
              <MenuItem value="poupanca">Poupança</MenuItem>
              <MenuItem value="corrente">Corrente</MenuItem>
            </Select>
            {
              // eslint-disable-next-line no-nested-ternary
              errors
                ? errors.type.error
                ? (<FormHelperText error>{errors.type.message}</FormHelperText>)
                : ''
              : ''
            }
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
