import { put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { AuthActionTypes } from './types';
import {
  loginFailure,
  loginSuccess,
  updatePasswordFailure,
  updatePasswordSuccess,
  recoveryPasswordSuccess,
  recoveryPasswordFailure,
} from './actions';

function* login({ payload }: AnyAction) {
  try {
    const res = yield api.post('/account/signin', {
      email: payload.email,
      password: payload.password,
    });

    yield put(loginSuccess(res.data.token));
    yield put(push('/identify'));
  } catch (e) {
    yield put(loginFailure(e.response));
  }
}

function* recoveryPassword({ payload }: AnyAction) {
  try {
    yield api.post('/account/recovery-password', {
      email: payload.email,
    });
    yield put(recoveryPasswordSuccess());
  } catch (e) {
    yield put(recoveryPasswordFailure(e.response));
  }
}

function* updatePassword({ payload }: AnyAction) {
  try {
    const res = yield api.post(
      '/account/update-password-with-token',
      {
        newPassword: payload.newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      },
    );
    yield put(updatePasswordSuccess(res.data));
  } catch (e) {
    yield put(updatePasswordFailure(e.response));
  }
}

function* authSagas() {
  yield takeLatest(AuthActionTypes.LOGIN_REQUEST, login);
  yield takeLatest(AuthActionTypes.RECOVERY_PASSWORD_REQUEST, recoveryPassword);
  yield takeLatest(AuthActionTypes.UPDATE_PASSWORD_REQUEST, updatePassword);
}

export default authSagas;
