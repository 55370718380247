import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Reproved from 'assets/icons/CADASTRO-NEGADO.png';
import Waiting from 'assets/icons/CADASTRO-EM-ANALISE.png';
import { RouteComponentProps } from 'react-router-dom';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as AuthActions from 'store/ducks/auth/actions';
import { Container, Block, Icon } from './styles';

interface Params {
  type: string;
}

type DispatchProps = typeof AuthActions;

enum RegisterStatus {
  approved = 'approved',
  reproved = 'reproved',
  waiting = 'waiting',
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(AuthActions, dispatch);

type Props = RouteComponentProps<Params> & DispatchProps

/* type = RegisterStatus.waiting is just for testing // remove '= RegisterStatus.waiting' for deployment */
function EndRegister({ match, logout }: Props) {
  const { type } = match.params;
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [img, setImg] = useState();

  useEffect(() => {
    if (type !== undefined) {
      if (type === RegisterStatus.reproved) {
        setMessage(
          'Infelizmente não foi possível darmos continuidade no seu cadastro como parceiro Beelog. Entre em contato para saber o que pode ter dado errado no seu cadastro.',
        );
        setImg(Reproved);
        setTitle('CADASTRO NEGADO');
      } else if (type === RegisterStatus.waiting) {
        setMessage(
          'Estamos no processo de análise. Em breve você terá novidades sobre sua conta Beelog.',
        );
        setTitle('CADASTRO EM ANÁLISE');
        setImg(Waiting);
      } else if (type === RegisterStatus.approved) {
        // REDIRECT
      }
    }
  }, [type]);

  return (
    <Container container>
      <Block item md={12} container direction="row">
        <Grid item md={12} container>
          <Grid
            item
            md={12}
            style={{
              fontSize: '300%',
              fontWeight: 900,
              lineHeight: 'normal',
              color: '#2b085b',
            }}
          >
            {title}
          </Grid>
          <Grid item md={12} style={{ color: '#2b085b' }}>
            {message}
          </Grid>
        </Grid>
        <Grid item md={12} container>
          <Grid item md={4} />
          <Grid item md={4}>
            <Icon src={img} alt="icon" />
          </Grid>
          <Grid item md={4} />
        </Grid>
        <Grid item md={12}>
          <Button fullWidth color="primary" variant="contained" onClick={() => logout()}>
            Sair
          </Button>
        </Grid>
      </Block>
    </Container>
  );
}

export default connect(null, mapDispatchToProps)(EndRegister);
