import React, { useState, useEffect } from 'react';
import {
 Grid, Select, MenuItem, InputAdornment, FormHelperText,
} from '@material-ui/core';
import Input from 'components/Input';
import {
  CarIcon,
  CepIcon,
  MarkerIcon,
  CarModelIcon,
  HomeIcon,
  FlagIcon,
  EnterpriseIcon,
} from 'assets/icons';
import Validator from 'utils/validator';
import { Icon } from 'components/Input/styles';
import Title from '../Title';

const validator = new Validator();

export default function DriverData({
  getAddress,
  states,
  getStates,
  cities,
  getCities,
  address,
  driverDataRegister,
  clicked,
  setChecked,
  userDriverRegister,
}: any) {
  const [plate, setPlate] = useState('');
  const [type, setType] = useState(-1);
  const [description, setDescription] = useState('');
  const [cep, setCep] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [number, setNumber] = useState('');
  const [district, setDistrict] = useState('');
  const [cityId, setCityId] = useState(-1);
  const [stateId, setStateId] = useState(-1);
  const [statesState, setStatesState] = useState([]);
  const [citiesState, setCitiesState] = useState([]);
  const [errors, setErrors] = useState();

  const rules = [
    {
      field: 'plate', method: validator.isCarPlate, value: plate, message: 'Placa inválida',
    },
    {
      field: 'description', method: validator.isNotEmpty, value: description, message: 'O campo descrição é obrigatório',
    },
    {
      field: 'cep', method: validator.checkCep, value: cep, message: 'Cep inválido',
    },
    {
      field: 'line1', method: validator.isNotEmpty, value: line1, message: 'O campo logradouro é obrigatório',
    },
    {
      field: 'number', method: validator.isNotEmpty, value: number, message: 'O campo número é obrigatório',
    },
    {
      field: 'district', method: validator.isNotEmpty, value: district, message: 'O campo bairro é obrigatório',
    },
    {
      field: 'type', method: validator.checkSelect, value: type, message: 'O campo Veículo é obrigatório',
    },
    {
      field: 'state', method: validator.checkSelect, value: stateId, message: 'O campo estado é obrigatório',
    },
    {
      field: 'city', method: validator.checkSelect, value: cityId, message: 'O campo cidade é obrigatório',
    },
  ];

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (userDriverRegister !== undefined) {
      setPlate(userDriverRegister.plate);
      setCep(userDriverRegister.cep);
      setType(userDriverRegister.type);
      setDescription(userDriverRegister.description);
      setLine1(userDriverRegister.line1);
      setLine2(userDriverRegister.line2);
      setNumber(userDriverRegister.number);
      setDistrict(userDriverRegister.district);
      setCityId(userDriverRegister.cityId);
      setStateId(userDriverRegister.stateId);
    }
  }, [userDriverRegister]);

  useEffect(() => {
    if (states !== undefined) {
      setStatesState(states.estados);
    }
  }, [states]);

  useEffect(() => {
    if (cep !== undefined) {
      if (cep.length === 8) {
        getAddress(cep);
      }
    }
  }, [cep]);

  function renderStateSelect(statesParams: any) {
    return statesParams.map((state: any) => (
      <MenuItem key={state.id} value={state.id}>
        {state.nome}
      </MenuItem>
      ));
  }

  useEffect(() => {
    if (stateId) {
      getCities(stateId);
    }
  }, [stateId, getCities]);

  useEffect(() => {
    if (cities !== undefined) {
      setCitiesState(cities.cidades);
    }
  }, [cities]);

  useEffect(() => {
    if (address !== undefined) {
      if (!address.erro) {
        setDistrict(address.bairro);
        setLine2(address.complemento);
        setLine1(address.logradouro);
      }
    }
  }, [address]);

  function renderCitySelect(cityParams: any) {
    return cityParams.map((city: any) => (
      <MenuItem key={city.id} value={city.id}>
        {city.nome}
      </MenuItem>
      ));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function formValidation() {
    setErrors(validator.validateForm(rules));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleSubmit() {
    // eslint-disable-next-line max-len
    if (
      !errors.plate.error
      && !errors.description.error
      && !errors.cep.error
      && !errors.line1.error
      && !errors.number.error
      && !errors.district.error
      && !errors.type.error
      && !errors.state.error
      && !errors.city.error
    ) {
      driverDataRegister({
        plate,
        description,
        cep,
        line1,
        number,
        district,
        cityId,
        stateId,
        type,
      });
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (clicked) {
      formValidation();
    }
  }, [clicked]);

  useEffect(() => {
    if (errors !== undefined) {
      const submit = handleSubmit();

      if (submit) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [errors, setChecked, handleSubmit]);

  return (
    <React.Fragment>
      <Title text="Cadastro de parceiro" />
      <Grid item md={12} container justify="center" spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={12} container>
            <Input
              name="plate"
              placeholder="Placa"
              icon={CarIcon}
              value={plate}
              onChange={evt => setPlate(evt.target.value.toUpperCase())}
              error={errors ? errors.plate.error : false}
              errorText={errors ? errors.plate.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Select
              value={type}
              fullWidth
              onChange={(evt: any) => setType(evt.target.value)}
              style={{ color: 'gray' }}
              error={errors ? errors.type.error : false}
              inputProps={{
                name: 'type',
                id: 'type',
              }}
              startAdornment={(
                <InputAdornment position="start">
                  <Icon src={CarModelIcon} alt="CAR_TYPE" />
                </InputAdornment>
              )}
            >
              <MenuItem disabled value={-1}>
                Veículo
              </MenuItem>
              <MenuItem value={0}>Moto</MenuItem>
              <MenuItem value={1}>Carro</MenuItem>
            </Select>
            {// eslint-disable-next-line no-nested-ternary
            errors ? (
              errors.type.error ? (
                <FormHelperText error>{errors.type.message}</FormHelperText>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </Grid>
          <Grid item md={12} container>
            <Input
              name="description"
              placeholder="Descrição"
              icon={CarIcon}
              value={description}
              onChange={evt => setDescription(evt.target.value)}
              error={errors ? errors.description.error : false}
              errorText={errors ? errors.description.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Input
              name="cep"
              placeholder="CEP"
              icon={CepIcon}
              value={cep}
              onChange={evt => setCep(evt.target.value.replace(/\D/, ''))}
              error={errors ? errors.cep.error : false}
              errorText={errors ? errors.cep.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Input
              name="line1"
              placeholder="Logradouro"
              icon={MarkerIcon}
              value={line1}
              onChange={evt => setLine1(evt.target.value)}
              error={errors ? errors.line1.error : false}
              errorText={errors ? errors.line1.message : ''}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={12} container>
            <Input
              name="number"
              placeholder="Número"
              icon={HomeIcon}
              value={number}
              onChange={evt => setNumber(evt.target.value)}
              error={errors ? errors.number.error : false}
              errorText={errors ? errors.number.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Input
              name="line2"
              placeholder="Complemento"
              icon={FlagIcon}
              value={line2}
              onChange={evt => setLine2(evt.target.value)}
            />
          </Grid>
          <Grid item md={12} container>
            <Input
              name="district"
              placeholder="Bairro"
              icon={EnterpriseIcon}
              value={district}
              onChange={evt => setDistrict(evt.target.value)}
              error={errors ? errors.district.error : false}
              errorText={errors ? errors.district.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Select
              value={stateId}
              fullWidth
              onChange={(evt: any) => setStateId(evt.target.value)}
              error={errors ? errors.state.error : false}
              style={{ color: 'gray' }}
              inputProps={{
                name: 'stateId',
                id: 'stateId',
              }}
            >
              <MenuItem disabled value={-1}>
                Estado
              </MenuItem>
              {renderStateSelect(statesState || [])}
            </Select>
            {// eslint-disable-next-line no-nested-ternary
            errors ? (
              errors.state.error ? (
                <FormHelperText error>{errors.state.message}</FormHelperText>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </Grid>
          <Grid item md={12} container>
            <Select
              value={cityId}
              fullWidth
              onChange={(evt: any) => setCityId(evt.target.value)}
              style={{ color: 'gray' }}
              error={errors ? errors.state.error : false}
              inputProps={{
                name: 'cityId',
                id: 'cityId',
              }}
            >
              <MenuItem disabled value={-1}>
                Cidade
              </MenuItem>
              {renderCitySelect(citiesState || [])}
            </Select>
            {// eslint-disable-next-line no-nested-ternary
            errors ? (
              errors.city.error ? (
                <FormHelperText error>{errors.city.message}</FormHelperText>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
