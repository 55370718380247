import React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface Props {
  text: string;
}

export default function Title({ text }: Props) {
  return (
    <Grid item md={12}>
      <Typography variant="h5" color="primary">
        {text}
      </Typography>
    </Grid>
  );
}
