import Register from 'pages/Register';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import EndRegister from 'pages/EndRegister';
import Forgot from 'pages/Forgot';
import Roll from 'pages/Roll';
import NewPassword from 'pages/NewPassword';
import Indentify from 'pages/Identify';
import DriverDocuments from 'pages/DriverDocuments';
import Logout from 'pages/Logout';
import Sandbox from 'pages/Sandbox';

export const routes = [
  {
    path: '/register',
    component: Register,
    protected: false,
  },
  {
    path: '/steps',
    component: Roll,
    protected: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    protected: true,
  },
  {
    path: '/login',
    component: Login,
    protected: false,
  },
  {
    path: '/end-register/:type',
    component: EndRegister,
    protected: true,
  },
  {
    path: '/forgot',
    component: Forgot,
    protected: false,
  },
  {
    path: '/newpassword',
    component: NewPassword,
    protected: false,
  },
  {
    path: '/identify',
    component: Indentify,
    protected: false,
  },
  {
    path: '/driver-documents',
    component: DriverDocuments,
    protected: true,
  },
  {
    path: '/logout',
    component: Logout,
    protected: false,
  },
  {
    path: '/test',
    component: Sandbox,
    protected: false,
  },
];
