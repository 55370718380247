import { Reducer } from 'redux';
import { AuthState, AuthActionTypes } from './types';

const INITIAL_STATE: AuthState = {
  isLoading: false,
  token: localStorage.getItem('token') || '',
  error: undefined,
  success: undefined,
  recovery: undefined,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.AUTO_LOGIN:
      return { ...state, token: action.payload.token };

    case AuthActionTypes.LOGIN_REQUEST:
      return { ...state, isLoading: true };
    case AuthActionTypes.LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      return { ...state, isLoading: false, token: action.payload.token };
    case AuthActionTypes.LOGIN_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };

    case AuthActionTypes.UPDATE_PASSWORD_REQUEST:
      return { ...state, isLoading: true };
    case AuthActionTypes.UPDATE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, success: action.payload.token };
    case AuthActionTypes.UPDATE_PASSWORD_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };

    case AuthActionTypes.LOGOUT:
      return { ...state };

    case AuthActionTypes.RECOVERY_PASSWORD_REQUEST:
      return { ...state, recovery: true, isLoading: true };
    case AuthActionTypes.RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        recovery: true,
        success: true,
        isLoading: false,
      };
    case AuthActionTypes.RECOVERY_PASSWORD_FAILURE:
      return { ...state, error: action.payload.error, isLoading: false };
    default:
      return state;
  }
};

export { reducer as auth };
