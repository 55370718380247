import React from 'react';

// import { Container } from './styles';
interface Props {
  text: string;
  color: string;
  handlerClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}

export default function TextButton({ text, color, handlerClick }: Props) {
  return (
    <button
      type="button"
      onClick={handlerClick}
      style={{
        color,
        border: 'none',
        padding: 0,
        font: 'inherit',
        background: 'none',
        cursor: 'pointer',
        outline: 'inherit',
      }}
    >
      {text}
    </button>
  );
}
