import { action } from 'typesafe-actions';
import { AuthActionTypes } from './types';

export const autoLogin = () => action(AuthActionTypes.AUTO_LOGIN, {
    token: localStorage.getItem('token') || '',
  });

/* eslint-disable  max-len */
export const loginRequest = (email: string, password: string) => action(AuthActionTypes.LOGIN_REQUEST, {
    email,
    password,
  });

export const loginSuccess = (token: string) => action(AuthActionTypes.LOGIN_SUCCESS, {
    token,
  });

export const loginFailure = (error: string) => action(AuthActionTypes.LOGIN_FAILURE, {
    error,
  });

export const recoveryPassword = (email: string) => action(AuthActionTypes.RECOVERY_PASSWORD_REQUEST, { email });

export const recoveryPasswordSuccess = () => action(AuthActionTypes.RECOVERY_PASSWORD_SUCCESS, {
});

export const recoveryPasswordFailure = (error: string) => action(AuthActionTypes.RECOVERY_PASSWORD_FAILURE, {
  error,
});

export const updatePassword = (token: string, newPassword: string) => action(AuthActionTypes.UPDATE_PASSWORD_REQUEST, { token, newPassword });

export const updatePasswordSuccess = (token: string) => action(AuthActionTypes.UPDATE_PASSWORD_SUCCESS, {
  token,
});

export const updatePasswordFailure = (error: string) => action(AuthActionTypes.UPDATE_PASSWORD_FAILURE, {
  error,
});

export const logout = () => action(AuthActionTypes.LOGOUT);
