import React from 'react';
import { Grid } from '@material-ui/core';
import { Block } from './styles';

export default function Success() {
  return (
    <Block item md={12} container direction="row">
      <Grid md={12} />
      <Grid
        item
        md={12}
        style={{
          fontSize: '150%',
          fontWeight: 900,
          lineHeight: 'normal',
          color: '#ff0b54',
        }}
      >
        O Email com as intruções foi enviado para seu email.
      </Grid>
    </Block>
  );
}
