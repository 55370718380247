import React from 'react';

import { Btn } from './styles';

interface Props extends React.ComponentProps<typeof Btn> {
  children: string;
}

export default function Button({ children, color, ...rest }: Props) {
  return (
    <Btn color={color} fullWidth {...rest}>
      {children!.toUpperCase()}
    </Btn>
  );
}
