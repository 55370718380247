import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Email from 'assets/icons/ICONE-EMAIL.png';
import Input from 'components/Input';
import Button from 'components/Button';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as AuthActions from 'store/ducks/auth/actions';
import { ApplicationState } from 'store';
import Success from './success';
import { Container, Block } from './styles';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

type DispatchProps = typeof AuthActions;

interface StateProps {
  recovery: boolean;
}

type Props = RouteComponentProps & DispatchProps & StateProps;

function Forgot({ recoveryPassword, recovery }: Props) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (recovery !== undefined) {
      if (!recovery) {
        setError(true);
        setErrorText('Email não cadastrado!');
      }
    }
  }, [recovery]);

  function handleSubmit(email: string) {
    if (emailRegex.test(email)) {
      recoveryPassword(email);
      setError(false);
    } else {
      setErrorText('Email Inválido!');
      setError(true);
    }
  }

  return (
    <Container container>
      {
          recovery
          ? (<Success />)
          : (
            <Block item md={12} container direction="row">
              <Grid item md={12} container>
                <Grid
                  item
                  md={12}
                  style={{
                    fontSize: '150%',
                    fontWeight: 900,
                    lineHeight: 'normal',
                    color: '#ff0b54',
                  }}
                >
                  Recuperação de senha
                </Grid>
                <Grid item md={12} style={{ color: '#ff0b54' }}>
                  Digite seu email para receber instruções de como obter uma nova senha.
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item md={12}>
                  <Input
                    color="#ff0b54"
                    name="email"
                    placeholder="Email"
                    icon={Email}
                    onChange={evt => setEmail(evt.target.value)}
                    error={error}
                    errorText={errorText}
                  />
                </Grid>
              </Grid>
              <Grid item md={12} container>
                <Grid item xs={12} sm={12} md={12} style={{ padding: '0 10px' }}>
                  <Button color="secondary" variant="contained" onClick={() => handleSubmit(email)}>
                    enviar
                  </Button>
                </Grid>
              </Grid>
            </Block>
          )
        }
    </Container>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(AuthActions, dispatch);

const mapStateToProps = (state: ApplicationState) => ({
  recovery: state.auth.recovery,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Forgot);
