import styled from 'styled-components';
import { Input } from '@material-ui/core';

export const Field = styled(Input)`
  && {
    /* color: ${props => props.color}; */
    ::before {
      border-color: ${props => props.color};
      border-width: 1px;
    }
    ::after {
      border-color: ${props => props.color};
    }
  }
`;
export const Icon = styled.img`
  padding: 8pt;
  width: 15px;
`;
