import { action } from 'typesafe-actions';
import { User } from 'models/User/user';
import { UserActionTypes } from './types';

export const setIsIdentify = (value: boolean) => action(UserActionTypes.SET_IDENTIFY, {
    isIdentify: value,
  });

export const identifyRequest = () => action(UserActionTypes.IDENTIFY_REQUEST, {
    token: localStorage.getItem('token') || '',
  });

export const identifySuccess = (authUser: User, isIdentify: boolean) => action(UserActionTypes.IDENTIFY_SUCCESS, {
    authUser,
    isIdentify,
  });

export const identifyFailure = (error: string) => action(UserActionTypes.IDENTIFY_FAILURE, {
    error,
  });

export const startRegister = (model: object) => action(UserActionTypes.START_REGISTER, { model });

export const userDataRegister = (model: object) => action(UserActionTypes.USER_DATA_REGISTER, { model });

export const termsRegister = (model: object) => action(UserActionTypes.TERMS_REGISTER, { model });

export const driverDataRegister = (model: object) => action(UserActionTypes.DRIVER_DATA_REGISTER, { model });

export const bankDataRegister = (model: object) => action(UserActionTypes.BANK_DATA_REGISTER, { model });

export const documentsRegister = (model: object) => action(UserActionTypes.DOCUMENTS_REGISTER, { model });

export const getAddress = (zipcode: string) => action(UserActionTypes.GET_ADDRESS, { zipcode });

export const getStates = () => action(UserActionTypes.GET_STATES, {});

export const getCities = (stateId: number) => action(UserActionTypes.GET_CITIES, { stateId });

export const getBanks = () => action(UserActionTypes.GET_BANKS, {});

export const validateEmail = (email: string) => action(UserActionTypes.VALIDATE_EMAIL, { email });

export const validateCpf = (cpf: string) => action(UserActionTypes.VALIDATE_CPF, { cpf });

export const validatePhone = (phone: string) => action(UserActionTypes.VALIDATE_PHONE, { phone });

export const resetUser = (name: string) => action(UserActionTypes.RESET_USER, { name });

export const registerUser = (model: object) => action(UserActionTypes.REGISTER_USER, { model });
