import {
 createStore, compose, applyMiddleware, Store,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import sagas from './ducks/rootSaga';
import history from '../routes/history';
import { UserState } from './ducks/user/types';
import { AuthState } from './ducks/auth/types';
import rootReducer from './ducks/rootReducer';

export interface ApplicationState {
  user: UserState;
  auth: AuthState;
}

const middlewares = [routerMiddleware(history)];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

const composer = process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares, logger))
    : compose(applyMiddleware(...middlewares));

const store: Store<ApplicationState> = createStore(rootReducer, composer);

sagaMiddleware.run(sagas);

export default store;
