import { Reducer } from 'redux';
import { UserState, UserActionTypes } from './types';

const INITIAL_STATE: UserState = {
  isIdentify: false,
  isLoading: false,
  userAuth: undefined,
  userRegister: undefined,
  userStartRegisterState: { name: '', phone: '' },
  userDataRegisterState: {
    email: '',
    password: '',
    cpf: '',
    gender: 0,
  },
  userDriverRegister: undefined,
  states: undefined,
  cities: undefined,
  address: undefined,
  banks: undefined,
  userBankRegister: undefined,
  register: undefined,
  emailValid: undefined,
  cpfValid: undefined,
  phoneValid: undefined,
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_IDENTIFY:
      return { ...state, isIdentify: action.payload.isIdentify };
    case UserActionTypes.IDENTIFY_REQUEST:
      return { ...state, isLoading: true };
    case UserActionTypes.IDENTIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userAuth: action.payload.authUser,
        isIdentify: true,
      };
    case UserActionTypes.IDENTIFY_FAILURE:
      return { ...state, isLoading: false, error: action.payload.error };
    case UserActionTypes.START_REGISTER:
      return { ...state, userStartRegisterState: action.payload.model };
    case UserActionTypes.USER_DATA_REGISTER:
      return { ...state, emailValid: false, userDataRegisterState: action.payload.model };
    case UserActionTypes.DRIVER_DATA_REGISTER:
      return { ...state, userDriverRegister: action.payload.model };
    case UserActionTypes.GET_ADDRESS_SUCCESS:
      return { ...state, address: action.payload.address };
    case UserActionTypes.GET_ADDRESS_FAILURE:
      return { ...state, error: action.payload.error };
    case UserActionTypes.GET_CITIES_SUCCESS:
      return { ...state, cities: action.payload.cities };
    case UserActionTypes.GET_CITIES_FAILURE:
      return { ...state, error: action.payload.error };
    case UserActionTypes.GET_STATES_SUCCESS:
      return { ...state, states: action.payload.states };
    case UserActionTypes.GET_STATES_FAILURE:
      return { ...state, error: action.payload.error };
    case UserActionTypes.GET_BANKS_SUCCESS:
      return { ...state, banks: action.payload.banks };
    case UserActionTypes.GET_BANKS_FAILURE:
      return { ...state, error: action.payload.error };
    case UserActionTypes.BANK_DATA_REGISTER:
      return { ...state, userBankRegister: action.payload.model };
    case UserActionTypes.REGISTER_USER:
      return { ...state, isLoading: true };
    case UserActionTypes.REGISTER_USER_SUCCESS:
      localStorage.setItem('token', action.payload.register.token);
      return { ...state, isLoading: false, register: action.payload.register };
    case UserActionTypes.REGISTER_USER_FAILURE:
      return { ...state, isLoading: false, register: action.payload.error };
    case UserActionTypes.VALIDATE_EMAIL_SUCCESS:
      return { ...state, emailValid: action.payload.data };
    case UserActionTypes.VALIDATE_EMAIL_FAILURE:
      return { ...state, emailValid: action.payload.error };
    case UserActionTypes.VALIDATE_CPF_SUCCESS:
      return { ...state, cpfValid: action.payload.data };
    case UserActionTypes.VALIDATE_CPF_FAILURE:
      return { ...state, cpfValid: action.payload.error };
    case UserActionTypes.VALIDATE_PHONE_SUCCESS:
      return { ...state, phoneValid: action.payload.data };
    case UserActionTypes.VALIDATE_PHONE_FAILURE:
      return { ...state, phoneValid: action.payload.error };
    case UserActionTypes.RESET_USER:
      return { ...state, [action.payload.name]: undefined };
    default:
      return state;
  }
};

export { reducer as user };
