import styled from 'styled-components';
import { Grid, Link } from '@material-ui/core';
import background from 'assets/images/BG-CADASTRO.png';

export const Container = styled(Grid)`
  && {
    overflow-y: scroll;
    font-family: 'Lato', sans-serif;
    height: 100vh;
    background-color: #fff;
    background-image: url(${background});
    background-repeat: no-repeat;
    @media (min-width: 768px) and (orientation: landscape) {
      background-size: contain;
      background-position-y: bottom;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      background-size: contain;
      background-position-y: 500px;
    }
  }
`;
export const Block = styled(Grid)`
  && {
    @media (min-width: 768px) and (orientation: landscape) {
      /* padding-top: 5%; */
      /* padding-left: 50%;
      padding-right: 20%; */
    }
    @media (min-width: 320px) and (max-width: 767px) {
      /* padding: 10%; */
    }
  }
`;
export const MyLink = styled(Link)`
  text-decoration: none;
`;
