import React from 'react';
import { InputAdornment, Typography } from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input';
import { Field, Icon } from './styles';

interface Props extends InputProps {
  icon: string;
  errorText?: string;
}

export default function Input({ errorText, icon, ...rest }: Props) {
  return (
    <React.Fragment>
      <Field
        {...rest}
        fullWidth
        color={rest.color}
        startAdornment={
          icon ? (
            <InputAdornment position="start">
              <Icon src={icon} alt={rest.name} />
            </InputAdornment>
          ) : null
        }
      />
      {rest.error ? (
        <Typography variant="caption" display="block" gutterBottom color="error">
          {errorText}
        </Typography>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}
