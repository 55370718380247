import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Password from 'assets/icons/ICONE-SENHA.png';
import Input from 'components/Input';
import Button from 'components/Button';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as AuthActions from 'store/ducks/auth/actions';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationState } from 'store';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, Block } from './styles';

type DispatchProps = typeof AuthActions;

interface StateProps {
  isLoading: boolean;
  error?: any;
  success?: any;
  newToken?: string;
}

type Props = RouteComponentProps & DispatchProps & StateProps;

function NewPassword({
 updatePassword, error, isLoading, success, history,
}: Props) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('Email e/ou senha incorretos.');
  const [open, setOpen] = React.useState(false);

  function findGetParameter(parameterName: string) {
    let result = null;
    let tmp = [];
    const items = window.location.search.substr(1).split('&');
    for (let index = 0; index < items.length; index += 1) {
      tmp = items[index].split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
  }

  function validateFields(newPassword: string, confirmNewPassword: string) {
    const passwordRegex = /^[A-Za-z0-9@$!%*#?&]+$/;

    if (newPassword.length < 8 || newPassword.length > 16) {
      setErrorMessage('A senha deve ter entre 8 e 16 caracteres.');
      setNewPasswordError(true);
      handleClick();
      return;
    }
    if (!passwordRegex.test(newPassword)) {
      setErrorMessage('Por favor escolha outra senha.');
      setNewPasswordError(true);
      handleClick();
      return;
    }
    setNewPasswordError(false);

    if (confirmNewPassword !== newPassword) {
      setErrorMessage('As senhas digitadas devem ser iguais.');
      setConfirmNewPasswordError(true);
      handleClick();
      return;
    }
    setConfirmNewPasswordError(false);

    updatePassword(token, newPassword);
  }

  function handleClick() {
    setOpen(true);
  }

  function handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  useEffect(() => {
    if (findGetParameter('token') == null) {
      history.push('/login');
    } else {
      setToken(findGetParameter('token')!);
    }
  }, []);

  useEffect(() => {
    if (success != null) {
      setErrorMessage('Senha alterada com sucesso!');
      handleClick();
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } else if (error != null) {
      setErrorMessage('Erro ao alterar senha! Tente novamente.');
      handleClick();
    }
  }, [error, success]);

  return (
    <Container container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{errorMessage}</span>}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Block item md={12} container direction="row">
        <Grid item md={12} container>
          <Grid
            item
            md={12}
            style={{
              fontSize: '150%',
              fontWeight: 900,
              lineHeight: 'normal',
              color: '#ff0b54',
            }}
          >
            Nova senha
          </Grid>
          <Grid item md={12} style={{ color: '#ff0b54' }}>
            Cadastre uma nova senha para voltar a acessar a sua conta do Beelog.
          </Grid>
        </Grid>
        <Grid item md={12} container>
          <Grid item md={12}>
            <Input
              color="#ff0b54"
              name="senha"
              placeholder="Senha"
              icon={Password}
              error={newPasswordError}
              type="password"
              required
              disabled={isLoading}
              onChange={evt => setNewPassword(evt.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item md={12} style={{ marginTop: '-10px' }} container>
          <Grid item md={12}>
            <Input
              color="#ff0b54"
              name="confirmar-senha"
              placeholder="Confirmar senha"
              icon={Password}
              error={confirmNewPasswordError}
              type="password"
              required
              disabled={isLoading}
              onChange={evt => setConfirmNewPassword(evt.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item md={12} container>
          <Grid item xs={12} md={12} style={{ padding: '0px', textAlign: 'center' }}>
            {isLoading ? (
              <CircularProgress size={50} />
            ) : (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => validateFields(newPassword, confirmNewPassword)}
              >
                enviar
              </Button>
            )}
          </Grid>
        </Grid>
      </Block>
    </Container>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(AuthActions, dispatch);

const mapStateToProps = (state: ApplicationState) => ({
  success: state.auth.success,
  error: state.auth.error,
  isLoading: state.auth.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPassword);
