import React from 'react';
import {
 Stepper0, Stepper1, Stepper2, Stepper3,
} from 'assets/images';

// import { Container } from './styles';

interface Props {
  step: number;
  text: string;
}

const images = [Stepper0, Stepper1, Stepper2, Stepper3];

export default function Stepper({ step, text }: Props) {
  return (
    <div>
      <img
        src={images[step]}
        alt={text}
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      />
    </div>
  );
}
