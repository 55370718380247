import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, FormHelperText } from '@material-ui/core';
import Name from 'assets/icons/ICONE-NOME.png';
import Phone from 'assets/icons/ICONE-TELEFONE.png';
import Input from 'components/Input';
import Button from 'components/Button';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as UserActions from 'store/ducks/user/actions';
import Validator from 'utils/validator';
import InputMask from 'inputmask';
import history from 'routes/history';
import { ApplicationState } from 'store';
import { Container, Block } from './styles';

const validator = new Validator();

type DispatchProps = typeof UserActions;

interface StateProps {
  userStartRegisterState?: any;
  phoneValid?: any;
}

type Props = RouteComponentProps & DispatchProps & StateProps;

function goToLogin() {
  history.push('/login');
}

function nextStep() {
  history.push('/steps');
}

function Register({
 startRegister, userStartRegisterState, phoneValid, validatePhone,
}: Props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState();
  const [phoneError, setPhoneError] = useState('');

  const rules = [
    {
      field: 'name', method: validator.isName, value: name, message: 'Nome inválido',
    },
    {
      field: 'phone', method: validator.isNotEmpty, value: phone, message: 'O campo telefone é obrigatório',
    },
  ];

  function formValidation() {
    setErrors(validator.validateForm(rules));
  }

  useEffect(() => {
    if (userStartRegisterState !== undefined) {
      setName(userStartRegisterState.name);
      setPhone(userStartRegisterState.phone);
      setPhoneError('');
    }
  }, [userStartRegisterState]);

  function handleSubmit() {
    if (!errors.name.error && !errors.phone.error) {
      startRegister({ name, phone });
      return true;
    }
    return false;
  }

  // useEffect(() => {
  //   if (phoneValid !== undefined) {
  //     if (phoneValid === true) {
  //       startRegister({ name, phone });
  //       nextStep();
  //     } else {
  //       setPhoneError(true);
  //     }
  //   }
  // }, [phoneValid]);

  useEffect(() => {
    if (phoneValid !== undefined) {
      if (phoneValid === true) {
        startRegister({ name, phone });
        nextStep();
        setPhoneError('');
      } else if (phoneValid === 400) {
        setPhoneError('Telefone inválido');
      } else if (phoneValid === 422) {
        setPhoneError('Telefone já cadastrado');
      }
    }
  }, [phoneValid]);

  useEffect(() => {
    if (errors !== undefined) {
      if (!errors.name.error && !errors.phone.error) {
        validatePhone(phone);
      }
    }
  }, [errors]);

  // useEffect(() => {
  //   if (errors !== undefined) {
  //     const submit = handleSubmit();
  //     if (submit) {
  //       nextStep();
  //     }
  //   }
  // }, [errors, handleSubmit]);

  function handlePhone(value: any) {
    if (value.target.value === '') {
      setPhone('');
      return;
    }
    const mask: any = InputMask({ mask: '(99) 99999-9999' }).mask(value.target);
    setPhone(mask.maskset.buffer.join(''));
  }

  return (
    <Container container>
      <Block item md={12} container alignItems="center">
        <Grid item md={12} container justify="center">
          <Grid
            item
            sm={12}
            md={12}
            style={{
              fontSize: '300%',
              fontWeight: 900,
              lineHeight: 'normal',
              color: '#2b085b',
            }}
          >
            GANHE
            <br />
            DINHEIRO
            <br />
            NO SEU
            <br />
            TEMPO
            <br />
          </Grid>
          <Grid item sm={12} md={12} style={{ color: '#2b085b' }}>
            Faça seu cadastro como fretista
            <br />
            e aumente sua renda mensal
          </Grid>
        </Grid>
        <Grid item md={12} container spacing={4} justify="center">
          <Grid item md={12} container>
            <Input
              value={name}
              placeholder="Nome"
              icon={Name}
              inputProps={{
                maxLength: 100
              }}
              onChange={evt => setName(evt.target.value)}
              error={errors ? errors.name.error : false}
              errorText={errors ? errors.name.message : ''}
            />
          </Grid>
          <Grid item md={12} container>
            <Input
              value={phone}
              name="phone"
              placeholder="Telefone"
              icon={Phone}
              onChange={evt => handlePhone(evt)}
              error={errors ? errors.phone.error : false}
              errorText={errors ? errors.phone.message : ''}
            />
            {
              phoneError ? (<FormHelperText error>{phoneError}</FormHelperText>) : ''
            }
          </Grid>
        </Grid>
        <Grid item md={12} container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Button color="primary" variant="contained" onClick={() => formValidation()}>
              continuar cadastro
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button color="primary" variant="outlined" onClick={goToLogin}>
              já sou cadastrado
            </Button>
          </Grid>
        </Grid>
      </Block>
    </Container>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  userStartRegisterState: state.user.userStartRegisterState,
  phoneValid: state.user.phoneValid,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Register);
