/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Email from 'assets/icons/ICONE-EMAIL.png';
import Senha from 'assets/icons/ICONE-SENHA.png';
import Input from 'components/Input';
import Button from 'components/Button';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as AuthActions from 'store/ducks/auth/actions';
import history from 'routes/history';
import { ApplicationState } from 'store';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Validator from 'utils/validator';
import { Container, Block, MyLink } from './styles';
import background from 'assets/images/BG-LOGIN.png';

type DispatchProps = typeof AuthActions;

interface StateProps {
  isLoading: boolean;
  error?: any;
}

type Props = RouteComponentProps & DispatchProps & StateProps;

function GoToRegister() {
  history.push('/register');
}

function GoToForgot() {
  history.push('/forgot');
}

const validator = new Validator();

function Login({ loginRequest, error: requestErr, isLoading }: Props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = useState();
  const [clicked, setClicked] = useState(false);
  const errorMessage = 'Email e/ou senha incorretos.';

  const rules = [
    {
      field: 'email',
      method: validator.isEmail,
      value: email,
      message: 'Email inválido',
    },
    {
      field: 'loginPassword',
      method: validator.isNotEmpty,
      value: password,
      message: 'O campo senha é obrigatório',
    },
  ];

  function formValidation() {
    setErrors(validator.validateForm(rules));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleSubmit() {
    if (!errors.email.error && !errors.loginPassword.error) {
      loginRequest(email, password);
    }
  }

  useEffect(() => {
    if (errors !== undefined) {
      handleSubmit();
    }
  }, [errors]);

  function handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    setOpen(false);
  }

  useEffect(() => {
    if (requestErr !== undefined) {
      setOpen(true);
    }
  }, [requestErr]);

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        formValidation();
      }}
    >
      <Container container>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{errorMessage}</span>}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />

        {/* <Block item xs={12} sm={12} md={6} container direction="row">
          <Grid item xs={false} md={12} container>
            <img src={background} style={{ width: '100%', height: '100%' }} />
          </Grid>
        </Block> */}
        <Block item xs={12} sm={12} md={12} container alignItems="center">
          <Grid item xs={12} sm={6} md={6} container />
          <Grid item xs={12} sm={6} md={6} container style={{padding: '10px'}}>
            <Grid
              item
              sm={12}
              md={12}
              style={{
                fontSize: '300%',
                fontWeight: 900,
                lineHeight: 'normal',
                color: '#2b085b',
              }}
            >
              ACESSE
              <br />
              A SUA
              <br />
              CONTA
              <br />
            </Grid>
            <Grid item xs={12} sm={12} style={{ color: '#2b085b', margin: '10px' }}>
              Entre com seu email e senha para
              <br />
              acessar as informações sobre sua conta e suas corridas.
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ overflow: 'hidden' }}>
              <Input
                name="email"
                placeholder="Email"
                icon={Email}
                value={email}
                error={errors ? errors.email.error : false}
                errorText={errors ? errors.email.message : ''}
                disabled={isLoading}
                onChange={(evt) => setEmail(evt.target.value)}
                style={{ margin: '5% 10px 5% 10px' }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ overflow: 'hidden' }}>
              <Input
                name="password"
                placeholder="Senha"
                icon={Senha}
                type="password"
                value={password}
                error={errors ? errors.loginPassword.error : false}
                errorText={errors ? errors.loginPassword.message : ''}
                disabled={isLoading}
                onChange={(evt) => setPassword(evt.target.value)}
                style={{ margin: '3% 10px 3% 10px' }}
              />
            </Grid>
            <Grid item container xs={12} sm={12} md={12} style={{ padding: '0 10px' }} justify="flex-end">
              <MyLink onClick={GoToForgot}>Esqueci minha senha</MyLink>
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ padding: '0 10px', textAlign: 'center' }}>
              {isLoading ? (
                <CircularProgress size={50} />
              ) : (
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  type="submit"
                  style={{ margin: '3% 0% 3% 0%' }}
                >
                  entrar
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ padding: '0 10px' }}>
              <Button
                color="primary"
                fullWidth
                variant="outlined"
                onClick={GoToRegister}
                style={{ margin: '3% 0% 3% 0%' }}
              >
                quero me cadastrar
              </Button>
            </Grid>
          </Grid>
        </Block>
      </Container>
    </form>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(AuthActions, dispatch);

const mapStateToProps = (state: ApplicationState) => ({
  error: state.auth.error,
  isLoading: state.auth.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
