/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input';
import Card from '@material-ui/core/Card';
import api from 'services/api';
import Button from 'components/Button';
import { FileUpload, FileInput, FileInputProps } from 'react-md';
import { display } from '@material-ui/system';
import { IconSuccess } from 'assets/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Icon } from './styles';

interface Props extends InputProps {
  icon: string;
  filePreview?: string | ArrayBuffer | null;
  description: string;
  imageType: ImageType;
  title: string;
  locked: boolean;
  handleUpdate: Function;
}

export enum ImageType {
  PROFILE = 'selfie',
  CRLV = 'vehicle_document',
  LICENSE = 'cnh',
  RECORDS = 'criminal_records',
}

export default function DocumentCard({
  title,
  icon,
  filePreview,
  description,
  imageType,
  handleUpdate,
  locked,
  ...rest
}: Props) {
  const [file, setFile] = useState(filePreview);
  const [reqErr, setReqErr] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (filePreview) {
      setFile(filePreview)
    }
  }, [filePreview])

  // async function handleSubmit(request: ImageType) {
  //   setLoading(true);
  //   const formData = new FormData();
  //   formData.set('image', file);
  //   let requisition = '';
  //   switch (request) {
  //     case ImageType.PROFILE:
  //       requisition = 'driver-selfie';
  //       break;
  //     case ImageType.CRLV:
  //       requisition = 'driver-vehicle-document';
  //       break;
  //     case ImageType.RECORDS:
  //       requisition = 'driver-criminal-records';
  //       break;
  //     case ImageType.LICENSE:
  //       requisition = 'driver-cnh';
  //       break;
  //     default:
  //       requisition = `driver-${request}`;
  //       break;
  //   }
  //   try {
  //     const res = await api.post(`/account/${requisition}`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     });
  //     setLoading(false);
  //     handleChange(res.data);
  //   } catch (error) {
  //     setLoading(false);
  //     setReqErr(error);
  //   }
  // }
  const fileUploadRef = useRef<any>(null);

  function clickFileUpload() {
    fileUploadRef.current.click();
  }

  function fileChange(evt: any) {
    const fil = evt.target.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(fil);
    reader.onloadend = (evt) => {
      const fileSize = fil.size / 1024;
      if (fileSize > 10240) {
        alert('Tamanho máximo de arquivo de excedido.');
      } else {
        setFile(String(reader.result));
        handleUpdate(fil);
      }
    }
  }

  return (
    <React.Fragment>
      <Card
        style={{
          textAlign: 'center',
          backgroundColor: '#fdfafd',
          padding: '15px',
          borderRadius: '15px',
          display: 'block',
        }}
        elevation={0}
      >
        <Grid container justify="center" alignContent="stretch" alignItems="flex-start">
          <Grid item xs={12} md={12}>
            <Icon src={icon} height={24} alt={title} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" color="primary">
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            component="p"
            style={{ fontSize: '12px', color: 'grey', textAlign: 'center', minHeight: '5vw' }}
          >
            {description}
          </Grid>
          <Grid
            item
            md={12}
            component="p"
            style={{ paddingTop: '10px', fontSize: '10px', color: '#320a5a' }}
          >
            <b>Máximo 10mb (apenas .jpg .png .jpeg)</b>
          </Grid>
          <Grid item xs={12} md={12}>
            {loading ? (
              <CircularProgress style={{ marginTop: '10px' }} size={30} />
            ) : (
              <div>
                <input
                  onChange={(evt) => fileChange(evt)}
                  style={{ display: 'none' }}
                  ref={fileUploadRef}
                  accept="image/jpg, image/jpeg, image/png, application/pdf"
                  type="file"
                  name="document"
                />

                {file ? (
                  <img
                    src={IconSuccess}
                    alt="Enviado"
                    style={{
                      position: 'relative',
                      top: '14px',
                      right: '0px',
                      zIndex: 5,
                      float: 'right',
                      visibility: locked ? 'hidden' : 'visible',
                    }}
                    width={24}
                  />
                ) : (
                  <div style={{ height: '20px', width: '24px' }} />
                )}

                <Button
                  color="primary"
                  variant="contained"
                  onClick={clickFileUpload}
                  style={{ fontSize: '10px', visibility: locked ? 'hidden' : 'visible' }}
                  fullWidth
                >
                  {file ? 'SUBSTITUIR ARQUIVO' : 'SELECIONAR ARQUIVO'}
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="outlined"
              style={{
                marginTop: '10px',
                fontSize: '10px',
                visibility: file && !loading ? 'visible' : 'hidden',
              }}
              fullWidth
              onClick={() => {
                const myWindow = window.open(String(file), '_blank');
                myWindow!.document.write(`<img src="${file}" alt="imagem" />`);
              }}
            >
              Ver imagem
            </Button>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
