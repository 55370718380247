/* eslint-disable */
const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const onlyNumber = /[^a-z ]\ *([.0-9])*\d/;
const dateRegex = /(([0-2]?[0-9]|3[0-1])(\.|\/|-|){1}([0]?[1-9]|1[0-2])(\.|\/|-|){1}[1-2]\d{3})( |){1}(20|21|22|23|[0-1]?\d{1})(:|){1}([0-5]?\d{1})/;
const bankAccountRegex = /^[0-9]{3,13}$/;
const accountDigitRegex = /^([0-9]{1,2}|[xX]{1})$/;
const bankBranchRegex = /^[0-9]{4}$/;
const branchDigitRegex = /^[0-9xX]{0,1}$/;
const bankVariationRegex = /^[0-9]{0,3}$/;
const phoneRegex = /^\([1-9]{2}\) (?:9[1-9])[0-9]{2,3}\-[0-9]{4}$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
const plateRegex = /^[A-z0-9]{7}$/;
const nameRegex = /^[0-9A-zÀ-ú ]{1,100}$/;
import { verifyCpf } from './functions';

class Validator {
  constructor() {
    this.isEmail = this.isEmail.bind(this);
    this.isNotEmpty = this.isNotEmpty.bind(this);
    this.isNumber = this.isNumber.bind(this);
    this.isCpf = this.isCpf.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.checkSelect = this.checkSelect.bind(this);
    this.checkSelectString = this.checkSelectString.bind(this);
    this.isBankAccount = this.isBankAccount.bind(this);
    this.isAccountDigit = this.isAccountDigit.bind(this);
    this.isBankBranch = this.isBankBranch.bind(this);
    this.isBranchDigit = this.isBranchDigit.bind(this);
    this.isBankVariation = this.isBankVariation.bind(this);
    this.isPhone = this.isPhone.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.isCarPlate = this.isCarPlate.bind(this);
    this.isName = this.isName.bind(this);
  }

  isBankAccount(value) {
    if (bankAccountRegex.test(value)) return true;
    return false;
  }

  isName(value) {
    if (nameRegex.test(value)) return true;
    return false;
  }

  isCarPlate(value) {
    if (plateRegex.test(value)) return true;
    return false;
  }

  isEmail(value) {
    if (emailRegex.test(value) && value.length <= 100) return true;
    return false;
  }

  isAccountDigit(value) {
    if (accountDigitRegex.test(value)) return true;
    return false;
  }

  isBankBranch(value) {
    if (bankBranchRegex.test(value)) return true;
    return false;
  }

  isBranchDigit(value) {
    if (branchDigitRegex.test(value)) return true;
    return false;
  }

  isBankVariation(value) {
    if (bankVariationRegex.test(value)) return true;
    return false;
  }

  isNotEmpty(value) {
    if (!value) return false;
    return true;
  }

  isPhone(value) {
    if (phoneRegex.test(value)) return true;
    return false;
  }

  isNumber(value) {
    if (onlyNumber.test(value)) return true;
    return false;
  }

  isCpf(value) {
    if (verifyCpf(value)) return true;
    return false;
  }

  isDate(value) {
    if (dateRegex.test(value)) return true;
    return false;
  }

  checkPassword(value1, value2) {
    if (value1 === value2) return true;
    return false;
  }

  validatePassword(value1) {
    if (passwordRegex.test(value1)) return true;
    return false;
  }

  // JUST FOR SELECTS WITH INT AS VALUE
  checkSelect(value) {
    if (value >= 0) return true;
    return false;
  }

  checkSelectString(value) {
    if (value !== 'none') return true;
    return false;
  }

  checkCep(value) {
    if (value.length === 8) return true;
    return false;
  }

  validateForm(rules) {
    const errors = [];

    rules.forEach((obj) => {
      const { method } = obj;
      if (obj.field === 'password') {
        if (!this.isNotEmpty(obj.value[0]) || !this.isNotEmpty(obj.value[1])) {
          errors[obj.field] = { error: true, message: 'O campo senha é obrigatório' };
        } else if (!this.validatePassword(obj.value[0])) {
          errors[obj.field] = {
            error: true,
            message:
              'A senha deve possuir de 8 a 16 caracteres e deve conter pelo menos um número e uma letra',
          };
        } else if (!method(obj.value[0], obj.value[1])) {
          errors[obj.field] = { error: true, message: obj.message };
        } else {
          errors[obj.field] = { error: false, message: '' };
        }
      } else if (!method(obj.value)) {
        errors[obj.field] = { error: true, message: obj.message };
      } else {
        errors[obj.field] = { error: false, message: '' };
      }
    });

    return errors;
  }
}

export default Validator;
