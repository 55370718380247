import React, { useState } from 'react';
import {
  Checkbox,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from 'components/Button';
import TextButton from 'components/TextButton';
import { maxWidth } from '@material-ui/system';

// import { Container } from './styles';

interface Props extends CheckboxProps {
  terms: boolean;
  handleChange: (value: React.SetStateAction<boolean>) => void;
}

export default function CheckboxTerms({ terms, handleChange, ...props }: Props) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Grid item md={1}>
        <Checkbox color="primary" checked={terms} onChange={() => handleChange(!terms)} />
      </Grid>
      <Grid item md={4}>
        <Typography variant="subtitle2" style={{ fontSize: '80%', color: 'gray' }}>
          Concordo com os
          {' '}
          <TextButton
            color="#2b085b"
            text="termos e condições"
            handlerClick={() => {
              window.open(
                'https://beelog.app/terms.html',
                '_blank'
              );
            }}
          />
        </Typography>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Termos</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <iframe src="https://beelog.app/terms.html" scrolling="yes" width="800px" height="600px"></iframe>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
