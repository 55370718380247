import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import background from 'assets/images/BG-CADASTRO.png';

export const Container = styled(Grid)`
  && {
    font-family: 'Lato', sans-serif;
    background-image: url(${background});
    background-repeat: no-repeat;
    position: fixed;
    height: 100vh;
    background-color: #fff;
    @media (min-width: 768px) and (orientation: landscape) {
      background-size: 50% 85%;
      background-position-y: bottom;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      background-size: contain;
      background-position-y: 500px;
    }
  }
`;
export const Block = styled(Grid)`
  && {
    padding-top: 5%;
    @media (min-width: 768px) and (orientation: landscape) {
      padding-left: 50%;
      padding-right: 20%;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 10% 20%;
    }
  }
`;
export const Icon = styled.img`
  padding: 8pt;
  width: 50%;
`;
