import React, { useState } from 'react';
import DocumentCard, { ImageType } from 'components/DocumentCard';
import { SelfieIcon } from 'assets/icons';


// import { Container } from './styles';

export default function Sandbox() {
  const [image, setImage] = useState();
  return (
    <div style={{ width: '30%' }}>
      <DocumentCard
        icon={SelfieIcon}
        filePreview="https://beelog.s3.us-east-2.amazonaws.com/dev/driver-documents/rZe/72e6c62f10ff7b098e6f6268f5a0dd8750e9e08d.png"
        title="Selfie"
        description="asudhw"
        imageType={ImageType.PROFILE}
        locked={false}
        handleUpdate={setImage}
      />
      {image ? <img src={image} alt="Test" /> : ''}
    </div>
  );
}
