import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import background from 'assets/images/BG-CADASTRO.png';

export const Container = styled(Grid)`
  && {
    font-family: 'Lato', sans-serif;
    @media (min-width: 768px) and (orientation: landscape) {
      background-size: 30% 50%;
      background-position-y: bottom;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      background-size: contain;
      background-position-y: 500px;
    }
  }
`;

export const Block = styled(Grid)`
  && {
    margin: 0% 10%;
    height: 100%;
    overflow: scroll;
    background-color: #fff;
  }
`;

export const CardContainer = styled(Grid)`
  && {
    padding: 0% 1%;
  }
`;
