import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as UserActions from 'store/ducks/user/actions';
import { ApplicationState } from 'store';
import history from 'routes/history';

const mapStateToProps = (state: ApplicationState) => ({
  userAuth: state.user.userAuth,
  token: state.auth.token,
  isIdentify: state.user.isIdentify,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(UserActions, dispatch);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = RouteComponentProps & DispatchProps & StateProps;

function Indentify({
 identifyRequest, isIdentify, userAuth, token, setIsIdentify,
}: Props) {
  useEffect(() => {
    identifyRequest();
  }, []);

  useEffect(() => {
    if (isIdentify) {
      if (userAuth !== undefined) {
        if (userAuth.driver) {
          if (userAuth.driver.approved_at != null) {
            window.location.href = `https://driver.beelog.app?token=${token}`;
          } else if (userAuth.driver.reproved_at != null) {
            history.push('/end-register/reproved');
          } else if (userAuth.driver.sent_documents) {
            history.push('/end-register/waiting');
          } else {
            history.push('/driver-documents');
          }
        } else {
          window.location.href = '/login';
        }
      }
      setIsIdentify(false);
    }
  }, [isIdentify]);

  return <div />;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Indentify);
