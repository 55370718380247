import React, { useState } from 'react';
import clsx from 'clsx';
import {
 Toolbar, IconButton, Typography, AppBar, Drawer, Divider, Grid,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useStyles } from './styles';

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={() => setOpen(true)}
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">Dashboard</Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} style={{ width: '240px' }}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item md={9}>
              Beelog
            </Grid>
            <Grid item md={3}>
              <IconButton onClick={() => setOpen(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <Divider />
      </Drawer>
    </div>
  );
}
