import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import background from 'assets/images/BG-CADASTRO.png';

export const Container = styled(Grid)`
  && {
    font-family: 'Lato', sans-serif;
    background-color: #fff;
    @media (min-width: 768px) and (orientation: landscape) {
      height: 100vh;
      background-size: 50% 85%;
      background-position-y: bottom;
      background-image: url(${background});
      background-repeat: no-repeat;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      height: 100vh;
      /* background-size: contain;
      background-position-y: 500px; */
    }
    overflow-y: scroll
  }
`;

export const Block = styled(Grid)`
  && {
    @media (min-width: 768px) and (orientation: landscape) {
      padding-top: 5%;
      padding-left: 50%;
      padding-right: 20%;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 0% 5%;
      justify-content: center;
    }
  }
`;
