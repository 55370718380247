import React, { useEffect } from 'react';
import { RouteProps, Route } from 'react-router-dom';
import history from 'routes/history';

interface PrivateProps extends RouteProps {
  authenticated?: boolean;
}

export function PrivateRoute(props: PrivateProps) {
  const token = localStorage.getItem('token');
  // TODO: pagina de forbidden
  useEffect(() => {
    if (!token) {
      history.push('/unauthenticated');
    }
  }, [token]);

  return <Route {...props} />;
}
