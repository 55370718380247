import { User } from 'models/User/user';
import {
  UserRegister,
  UserBankRegister,
  UserDataRegister,
  UserStartRegister,
  UserDriverRegister,
  Documents,
} from 'models/User/userRegister';

export enum UserActionTypes {
  SET_IDENTIFY = '@@auth/SET_IDENTIFY',
  IDENTIFY_REQUEST = '@@auth/IDENTIFY_REQUEST',
  IDENTIFY_SUCCESS = '@@auth/IDENTIFY_SUCCESS',
  IDENTIFY_FAILURE = '@@auth/IDENTIFY_FAILURE',
  START_REGISTER = '@@auth/START_REGISTER',
  USER_DATA_REGISTER = '@@auth/USER_DATA_REGISTER',
  TERMS_REGISTER = '@@auth/TERMS_REGISTER',
  DRIVER_DATA_REGISTER = '@@auth/DRIVER_DATA_REGISTER',
  BANK_DATA_REGISTER = '@@auth/BANK_DATA_REGISTER',
  DOCUMENTS_REGISTER = '@@auth/DOCUMENTS_REGISTER',
  GET_ADDRESS = '@@auth/GET_ADDRESS',
  GET_ADDRESS_SUCCESS = '@@auth/GET_ADDRESS_SUCCESS',
  GET_ADDRESS_FAILURE = '@@auth/GET_ADDRESS_FAILURE',
  GET_STATES = '@@auth/GET_STATES',
  GET_STATES_SUCCESS = '@@auth/GET_STATES_SUCCESS',
  GET_STATES_FAILURE = '@@auth/GET_STATES_FAILURE',
  GET_CITIES = '@@auth/GET_CITIES',
  GET_CITIES_SUCCESS = '@@auth/GET_CITIES_SUCCESS',
  GET_CITIES_FAILURE = '@@auth/GET_CITIES_FAILURE',
  GET_BANKS = '@@auth/GET_BANKS',
  GET_BANKS_SUCCESS = '@@auth/GET_BANKS_SUCCESS',
  GET_BANKS_FAILURE = '@@auth/GET_BANKS_FAILURE',
  REGISTER_USER = '@@auth/REGISTER_USER',
  REGISTER_USER_SUCCESS = '@@auth/REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE = '@@auth/REGISTER_USER_FAILURE',
  VALIDATE_EMAIL = '@@auth/VALIDATE_EMAIL',
  VALIDATE_EMAIL_SUCCESS = '@@auth/VALIDATE_EMAIL_SUCCESS',
  VALIDATE_EMAIL_FAILURE = '@@auth/VALIDATE_EMAIL_FAILURE',
  VALIDATE_CPF = '@@auth/VALIDATE_CPF',
  VALIDATE_CPF_SUCCESS = '@@auth/VALIDATE_CPF_SUCCESS',
  VALIDATE_CPF_FAILURE = '@@auth/VALIDATE_CPF_FAILURE',
  VALIDATE_PHONE = '@@auth/VALIDATE_PHONE',
  VALIDATE_PHONE_SUCCESS = '@@auth/VALIDATE_PHONE_SUCCESS',
  VALIDATE_PHONE_FAILURE = '@@auth/VALIDATE_PHONE_FAILURE',
  RESET_USER = '@@auth/RESET_USER',
}

export interface UserState {
  isIdentify: boolean;
  userAuth?: User;
  userRegister?: User;
  userBankRegister?: UserBankRegister;
  userDataRegisterState: UserDataRegister;
  userStartRegisterState: UserStartRegister;
  userDriverRegister?: UserDriverRegister;
  documents?: Documents;
  states?: any;
  cities?: any;
  address?: any;
  banks?: any;
  register?: any;
  readonly isLoading: boolean;
  emailValid?: any;
  cpfValid?: any;
  phoneValid?: any;
}
