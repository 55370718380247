import { put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';
import { UserActionTypes } from './types';

function* identify({ payload }: AnyAction) {
  try {
    const res = yield api.get('/account/identify', {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });

    yield put({ type: UserActionTypes.IDENTIFY_SUCCESS, payload: { authUser: res.data } });
  } catch (e) {
    yield put({ type: UserActionTypes.IDENTIFY_FAILURE, payload: { error: e } });
    yield put(push('/login'));
  }
}

function* getAddress({ payload }: AnyAction) {
  try {
    const res = yield api.get(`https://viacep.com.br/ws/${payload.zipcode}/json/`);

    yield put({ type: UserActionTypes.GET_ADDRESS_SUCCESS, payload: { address: res.data } });
  } catch (e) {
    yield put({ type: UserActionTypes.GET_ADDRESS_FAILURE, payload: { error: e } });
  }
}

function* getCities({ payload }: AnyAction) {
  try {
    const res = yield api.get('/account/cities', { params: { state_id: payload.stateId } });

    yield put({ type: UserActionTypes.GET_CITIES_SUCCESS, payload: { cities: res.data } });
  } catch (e) {
    yield put({ type: UserActionTypes.GET_CITIES_FAILURE, payload: { error: e } });
  }
}

function* getStates({ payload }: AnyAction) {
  try {
    const res = yield api.get('/account/states');

    yield put({ type: UserActionTypes.GET_STATES_SUCCESS, payload: { states: res.data } });
  } catch (e) {
    yield put({ type: UserActionTypes.GET_STATES_FAILURE, payload: { error: e } });
  }
}

function* getBanks({ payload }: AnyAction) {
  try {
    const res = yield api.get('https://account.pagcerto.com.br/api/v2/banks');

    yield put({ type: UserActionTypes.GET_BANKS_SUCCESS, payload: { banks: res.data } });
  } catch (e) {
    yield put({ type: UserActionTypes.GET_BANKS_FAILURE, payload: { error: e } });
  }
}

function* registerUser({ payload }: AnyAction) {
  try {
    const res = yield api.post('/account/driver-signup', payload.model);

    yield put({ type: UserActionTypes.REGISTER_USER_SUCCESS, payload: { register: res.data } });
  } catch (e) {
    yield put({ type: UserActionTypes.REGISTER_USER_FAILURE, payload: { error: e.response } });
  }
}

function* validateEmail({ payload }: AnyAction) {
  try {
    const res = yield api.post('/account/validate-email', { email: payload.email });

    yield put({ type: UserActionTypes.VALIDATE_EMAIL_SUCCESS, payload: { data: true } });
  } catch (e) {
    yield put({
      type: UserActionTypes.VALIDATE_EMAIL_FAILURE,
      payload: { error: e.response.data.error },
    });
  }
}

function* validateCpf({ payload }: AnyAction) {
  try {
    const res = yield api.post('/account/validate-cpf', { cpf: payload.cpf });

    yield put({ type: UserActionTypes.VALIDATE_CPF_SUCCESS, payload: { data: true } });
  } catch (e) {
    yield put({
      type: UserActionTypes.VALIDATE_CPF_FAILURE,
      payload: { error: e.response.status },
    });
  }
}

function* validatePhone({ payload }: AnyAction) {
  try {
    const res = yield api.post('/account/validate-phone', { phone: payload.phone });

    yield put({ type: UserActionTypes.VALIDATE_PHONE_SUCCESS, payload: { data: true } });
  } catch (e) {
    yield put({
      type: UserActionTypes.VALIDATE_PHONE_FAILURE,
      payload: { error: e.response.status },
    });
  }
}

function* userSagas() {
  yield takeLatest(UserActionTypes.IDENTIFY_REQUEST, identify);
  yield takeLatest(UserActionTypes.GET_ADDRESS, getAddress);
  yield takeLatest(UserActionTypes.GET_CITIES, getCities);
  yield takeLatest(UserActionTypes.GET_STATES, getStates);
  yield takeLatest(UserActionTypes.GET_BANKS, getBanks);
  yield takeLatest(UserActionTypes.REGISTER_USER, registerUser);
  yield takeLatest(UserActionTypes.VALIDATE_EMAIL, validateEmail);
  yield takeLatest(UserActionTypes.VALIDATE_CPF, validateCpf);
  yield takeLatest(UserActionTypes.VALIDATE_PHONE, validatePhone);
}

export default userSagas;
