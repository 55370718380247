import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Button from 'components/Button';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as UserActions from 'store/ducks/user/actions';
import history from 'routes/history';
import { ApplicationState } from 'store';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Container, Block } from './styles';
import Stepper from './components/Stepper';
import UserData from './components/UserData';
import Terms from './components/Terms';
import DriverData from './components/DriverData';
import BankData from './components/BankData';

interface StateProps {
  userDataRegisterState?: any;
  address?: any;
  states?: any;
  cities?: any;
  userDriverRegister?: any;
  userBankRegister?: any;
  banks?: any;
  userStartRegisterState?: any;
  register?: any;
  isLoading: boolean;
  emailValid?: any;
  cpfValid?: any;
}

type DispatchProps = typeof UserActions;
type Props = RouteComponentProps & DispatchProps & StateProps;

function Roll({
  userDataRegister,
  userDataRegisterState,
  getAddress,
  address,
  getStates,
  states,
  getCities,
  cities,
  driverDataRegister,
  userDriverRegister,
  userBankRegister,
  bankDataRegister,
  banks,
  getBanks,
  registerUser,
  userStartRegisterState,
  register,
  isLoading,
  emailValid,
  validateEmail,
  cpfValid,
  validateCpf,
  resetUser,
}: Props) {
  // TODO: setar counter default 0
  const [count, setCount] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [checked, setChecked] = useState();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (userStartRegisterState !== undefined) {
      if (userStartRegisterState.name === '') {
        history.push('/register');
      }
    }
  }, [userStartRegisterState]);

  useEffect(() => {
    if (register !== undefined) {
      if (register.token) {
        history.push('/identify');
      } else {
        resetUser('userBankRegister');
        setChecked(false);
        setClicked(false);
        setCount(3);
        setOpen(true);
      }
    }
  }, [register]);

  function handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    setOpen(false);
  }

  useEffect(() => {
    if (checked !== undefined) {
      if (checked) {
        setCount(count + 1);
        setChecked(false);
        setClicked(false);
      } else {
        setClicked(false);
      }
    }
  }, [checked, clicked]);

  const arrays = [
    <UserData
      userDataRegisterState={userDataRegisterState}
      userDataRegister={userDataRegister}
      clicked={clicked}
      setChecked={setChecked}
      emailValid={emailValid}
      validateEmail={validateEmail}
      cpfValid={cpfValid}
      validateCpf={validateCpf}
      resetUser={resetUser}
    />,
    <Terms clicked={clicked} setChecked={setChecked} resetUser={resetUser} />,
    <DriverData
      getAddress={getAddress}
      getStates={getStates}
      states={states}
      cities={cities}
      getCities={getCities}
      address={address}
      driverDataRegister={driverDataRegister}
      clicked={clicked}
      setChecked={setChecked}
      userDriverRegister={userDriverRegister}
    />,
    <BankData
      clicked={clicked}
      setChecked={setChecked}
      userBankRegister={userBankRegister}
      bankDataRegister={bankDataRegister}
      banks={banks}
      getBanks={getBanks}
      handleSignup={handleSignup}
      userDataRegisterState={userDataRegisterState}
    />,
  ];

  function handleBack() {
    if (count === 0) {
      history.push('/register');
    } else {
      setCount(count - 1);
    }
  }

  function handleSignup() {
    const user = {
      name: userStartRegisterState.name,
      email: userDataRegisterState.email,
      password: userDataRegisterState.password,
      phone: userStartRegisterState.phone,
      cpf: userDataRegisterState.cpf,
      gender: userDataRegisterState.gender,
      vehicle: {
        plate: userDriverRegister.plate,
        type: userDriverRegister.type,
        description: userDriverRegister.description,
      },
      address: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        city_id: userDriverRegister.cityId,
        zipcode: userDriverRegister.cep,
        district: userDriverRegister.district,
        number: userDriverRegister.number,
        line1: userDriverRegister.line1,
        line2: userDriverRegister.line2 || '',
      },
      bankAccount: {
        holderName: userBankRegister.holderName,
        taxDocument: userDataRegisterState.cpf,
        bankNumber: userBankRegister.bankNumber,
        accountNumber: userBankRegister.accountNumber,
        bankBranchNumber: userBankRegister.bankBranchNumber,
        variation: userBankRegister.variation || null,
        type: userBankRegister.type,
      },
    };
    registerUser(user);
  }

  function handleClick() {
    // if (count === 3) {
    //   handleSignup();
    // } else {
    //   setClicked(true);
    // }
    setClicked(true);
  }

  return (
    <Container container justify="center" style={{ padding: 20 }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Error ao cadastrar, tente novamente mais tarde.</span>}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Block container item md={12} alignItems="center" spacing={2}>
        {isLoading ? (
          <Grid
            item
            md={12}
            style={{
              margin: 'auto',
              width: '50%',
              padding: '10px',
              textAlign: 'center',
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item md={12} container justify="center">
              <Stepper step={count} text="stepper" />
            </Grid>
            <Grid item md={12} container alignItems="center" spacing={4} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
              {arrays[count]}
            </Grid>
            <Grid
              item
              md={12}
              container
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={6} container justify="center">
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ width: '200px' }}
                  onClick={() => handleBack()}
                >
                  VOLTAR
                </Button>
              </Grid>
              <Grid item xs={12} md={6} container justify="center">
                <Button
                  onClick={() => handleClick()}
                  color="primary"
                  variant="contained"
                  style={{ width: '200px' }}
                >
                  {// eslint-disable-next-line no-nested-ternary
                  count === 1 ? 'Aceitar' : count === 3 ? 'Cadastrar' : 'Próximo'}
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Block>
    </Container>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  userDataRegisterState: state.user.userDataRegisterState,
  address: state.user.address,
  states: state.user.states,
  cities: state.user.cities,
  userDriverRegister: state.user.userDriverRegister,
  userBankRegister: state.user.userBankRegister,
  banks: state.user.banks,
  userStartRegisterState: state.user.userStartRegisterState,
  register: state.user.register,
  isLoading: state.user.isLoading,
  emailValid: state.user.emailValid,
  cpfValid: state.user.cpfValid,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Roll);
