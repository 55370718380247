import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import Title from '../Title';

// import { Container } from './styles';

export default function Terms({ clicked, setChecked, resetUser }: any) {
  useEffect(() => {
    if (clicked) {
      setChecked(true);
    }
  }, [clicked, setChecked]);

  useEffect(() => {
    resetUser('emailValid');
    resetUser('cpfValid');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Title text="Termos e condições" />
      <Grid item md={12}>
        <iframe src="https://beelog.app/terms-driver.html" scrolling="yes" width="100%" height="800px"></iframe>
      </Grid>
    </React.Fragment>
  );
}
