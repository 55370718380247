import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Btn = styled(Button)`
  && {
    border-radius: 8px;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 12px;
    padding: 6px;
  }
`;
